import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bookingCheckout: {},
}

const bookingSlice = createSlice({
    name: "booking",
    initialState,
    reducers: {
        setBookingCheckout: (state, action) => {
            state.bookingCheckout = action.payload;
        }
    }
});

export const { setBookingCheckout } = bookingSlice.actions;
export default bookingSlice.reducer;