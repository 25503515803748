import React from 'react'
import loader from "../../assets/images/loader.gif"

const Preloader = () => {
  return (
    <div className='h-screen w-full flex items-center justify-center'>
        <img src={loader} alt="loader" />
    </div>
  )
}

export default Preloader