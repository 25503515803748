import React, { useEffect, useState } from "react";
import { Form, Select, message, Spin, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import TripCard from "../../components/tripCard/TripCard";
import TopBar from "../../components/topBar/TopBar";
import { useForm } from "antd/es/form/Form";
import { Link, useLocation } from "react-router-dom";

const Home = () => {
    const { allTrips, categories } = useSelector((state) => state.trip);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const cid = queryParams.get("cid");

    const dispatch = useDispatch();

    const [selectedDate, setSelectedDate] = useState(null);
    const [filteredTrips, setFilteredTrips] = useState([]);
    const [selectValue, setSelectValue] = useState("All");
    const [form] = useForm();
    const [loading, setLoading] = useState(false); // State to manage loading status
    const [allTripsShown, setAllTripsShown] = useState(false);
    const [selectedCat, setSelectedCat] = useState(null);

    useEffect(() => {
        if (!selectedDate) {
            // If no date is selected, show the first 5 trips
            setFilteredTrips(allTrips.slice(0, 5));
        } else {
            const formattedDate = formatDate(selectedDate);
            const active = allTrips.filter(
                (item) => item.startdate === formattedDate
            );
            if (active.length > 0) {
                setFilteredTrips(active.slice(0, 5));
            } else {
                message.error("No Trip Found");
                setFilteredTrips(null);
            }
        }
    }, [selectedDate, allTrips, dispatch]);

    const formatDate = (date) => {
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        if (month < 10) {
            month = `0${month}`;
        }
        let day = date.getDate();
        if (day < 10) {
            day = `0${day}`;
        }
        return `${year}-${month}-${day}`;
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value ? new Date(event.target.value) : null);
    };

    const nextTrip = () => {
        if (!selectedDate) return;
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() + 1);
        setSelectedDate(newDate);
    };

    const prevTrip = () => {
        if (!selectedDate) return;
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() - 1);
        setSelectedDate(newDate);
    };

    const clearFilter = () => {
        setFilteredTrips(allTrips.slice(0, 5)); // Reset to showing the first 5 trips
        setSelectedDate(null);
        setSelectValue("All");
        setSelectedCat(null);
        setAllTripsShown(false);

    };

    useEffect(() => {
        if (selectedCat && allTrips && allTrips.length > 0) {
            setFilteredTrips(allTrips.filter(item => +item.cid === +selectedCat));
            setAllTripsShown(true);
        }
        if (selectedCat === "all") {
            setFilteredTrips(allTrips);
            setAllTripsShown(true);
        }
    }, [selectedCat, allTrips]);

    const handleToggle = (category) => {
        if (selectedCat === category) {
            setSelectedCat('all'); // If the same category is clicked again, deselect and set to "all"
        } else {
            setSelectedCat(category);
        }
        setSelectValue(null);
    };

    useEffect(() => {
        if (cid) {
            setFilteredTrips(allTrips.filter((trip) => +trip.cid === +cid)); // Only show the first 5 trips matching the filter
        }
    }, [cid, allTrips]);

    const loadMoreTrips = () => {
        setLoading(true); // Set loading to true to display spinner
        setTimeout(() => {
            setFilteredTrips(allTrips);
            setLoading(false); // Set loading back to false after 2 seconds
            setAllTripsShown(true);
        }, 1000);
    };

    return (
        <>
            <TopBar />
            <div className="container px-3 sm:px-0 sm:mx-auto my-[15px] sm:my-[40px]">
                {!cid && (
                    <div>
                        <div className="flex items-center flex-col md:flex-row justify-between">
                            <div>
                                <h1 className="text-center max-sm:w-full sm:text-left text-[20px] sm:text-[35px] font-bold text-themeBlue text-shadow pb-2 max-sm:border-none inline-block border-b-4 border-themeRed">
                                    Upcoming Trips
                                </h1>
                                <div className="flex flex-col sm:flex-row mt-4 gap-5 justify-between items-center">
                                    <div className="flex gap-3 items-center">
                                        <div className="flex space-x-2">
                                            {categories.length > 0 &&
                                                categories.map((item) => (
                                                    <Button
                                                        key={item.id}
                                                        type={selectedCat === item.id ? 'primary' : 'default'}
                                                        onClick={() => handleToggle(item.id)}
                                                        className="border-2 rounded-md"
                                                    >
                                                        {item.name}
                                                    </Button>
                                                ))
                                            }
                                        </div>
                                        <div className="flex gap-1 sm:gap-4 items-center max-sm:hidden">
                                            <p className="text-[18px] text-gray-800 font-medium max-sm:text-[13px]">
                                                Search Trip
                                            </p>
                                            <Form form={form}>
                                                <Select
                                                    placeholder="Find Trip"
                                                    showSearch
                                                    value={selectValue}
                                                    defaultValue="All"
                                                    style={{
                                                        width: 190,
                                                    }}
                                                    options={[
                                                        { value: "All", label: "All" },
                                                        ...allTrips.map((trip) => ({
                                                            value: trip.title,
                                                            label: trip.title,
                                                            key: trip.id,
                                                        })),
                                                    ]}
                                                    onChange={(value, trip) => {
                                                        if (value === "All") {
                                                            setAllTripsShown(false);
                                                            setFilteredTrips(allTrips.slice(0, 5)); // Show first 5 trips when 'All' is selected
                                                        } else {
                                                            let active = allTrips.filter(
                                                                (trip) => trip.title === value
                                                            );
                                                            setFilteredTrips(active.slice(0, 5)); // Show first 5 filtered trips
                                                        }
                                                        setSelectValue(value);
                                                        setSelectedCat(null);
                                                    }}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    className="border-2 border-themeBlue rounded-md focus:border-themeBlue max-sm:text-[14px]"
                                                />
                                            </Form>
                                            <Button
                                                onClick={clearFilter}
                                                type="primary"
                                            // className="shadow-md rounded-md items-center bg-themeBlue text-[16px] max-sm:text-[13px] py-1 px-2 sm:text-[18px] sm:py-2 sm:px-3 font-medium hover:bg-themeRed duration-300 text-white"
                                            >
                                                Clear Filter
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="flex justify-end items-center max-sm:flex-col gap-3">
                                        <div className="flex gap-3 max-sm:gap-6 items-center">
                                            <p className="text-[18px] text-gray-800 font-medium max-sm:text-[14px] max-sm:hidden">
                                                Find By Date
                                            </p>

                                            <input
                                                type="date"
                                                className={`my-date-picker-style max-sm:p-0 max-sm:m-0 rounded-md ${selectedDate ? "" : "empty-date"}`}
                                                onChange={handleDateChange}
                                                value={
                                                    selectedDate ? selectedDate.toISOString().slice(0, 10) : ""
                                                }
                                            />
                                            <button
                                                onClick={clearFilter}
                                                className="shadow-md hidden max-sm:block rounded-md items-center bg-themeBlue text-[16px] max-sm:text-[13px] py-[6px] px-2 sm:text-[18px] sm:py-2 sm:px-3 font-medium hover:bg-themeRed duration-300 text-white"
                                            >
                                                Clear Filter
                                            </button>
                                        </div>
                                        {selectedDate && (
                                            <div className="flex items-center gap-3 max-sm:mt-4 fade-left max-sm:justify-between max-sm:w-[270px]">
                                                <Button
                                                    onClick={prevTrip}
                                                    type="primary"
                                                // className="shadow-md rounded-md gap-2 items-center bg-themeBlue text-[16px] py-1 px-2 sm:text-[18px] sm:py-2 sm:px-3 font-medium hover:bg-themeRed duration-300 text-white"
                                                >
                                                    Prev Day
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    onClick={nextTrip}
                                                // className="shadow-md rounded-md gap-2 items-center bg-themeBlue text-[16px] py-1 px-2 sm:text-[18px] sm:py-2 sm:px-3 font-medium hover:bg-themeRed duration-300 text-white"
                                                >
                                                    Next Day
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* filter only for desktop view  */}

                            {/* filter only for mobile view  */}

                            <div className="sm:hidden">

                                <p className="text-[18px] text-gray-800 text-center mt-3 font-medium max-sm:text-[14px]">
                                    Search Trip                                </p>
                                <div className="flex justify-between gap-1 sm:gap-4 items-center mt-2 sm:mt-8">


                                    <Form form={form}>
                                        <Select
                                            showSearch
                                            value={selectValue}
                                            defaultValue="All"
                                            options={[
                                                { value: "All", label: "All" },
                                                ...allTrips.map((trip) => ({
                                                    value: trip.title,
                                                    label: trip.title,
                                                    key: trip.id,
                                                })),
                                            ]}
                                            onChange={(value, trip) => {
                                                if (value === "All") {
                                                    setAllTripsShown(false);
                                                    setFilteredTrips(allTrips.slice(0, 5)); // Show first 5 trips when 'All' is selected
                                                } else {
                                                    let active = allTrips.filter(
                                                        (trip) => trip.title === value
                                                    );
                                                    setFilteredTrips(active.slice(0, 5)); // Show first 5 filtered trips
                                                }
                                                setSelectValue(value);
                                            }}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            className={`border-2 border-themeBlue rounded-md focus:border-themeBlue max-sm:text-[14px]  max-sm:w-[270px] w-[290px]`}
                                        />
                                    </Form>
                                </div>
                            </div>
                        </div>

                    </div>
                )}
                <div>
                    {filteredTrips && filteredTrips.length > 0 ? (
                        <div className="mt-6 sm:mt-10">
                            {filteredTrips.map((trip) => (
                                <TripCard key={trip.id} tripInfo={trip} />
                            ))}
                            {selectValue === "All" && !selectedDate && !cid && !allTripsShown &&
                                <div className="text-center mt-4">
                                    <button
                                        onClick={loadMoreTrips}
                                        className="bg-themeBlue text-white py-2 px-4 rounded-md hover:bg-themeRed"
                                        disabled={loading} // Disable button during loading
                                    >
                                        {loading ? <Spin /> : "Show All Trips"}
                                    </button>
                                </div>}
                            <div className="flex justify-center items-center gap-2 sm:gap-6 mt-[50px]">
                                <Link to={"https://tripsandtours.uk/"} target='_blank' className='text-[14px] sm:text-[18px] font-medium text-themeBlue border border-r-0 border-l-0 border-themeBlue px-2 py-1 sm:px-3 sm:py-2  shadow-[0_-2px_5px_rgba(0,0,0,0.1),0_2px_5px_rgba(0,0,0,0.1)] hover:border-themeRed hover:text-themeRed duration-300'>
                                    Visit Website
                                </Link>
                                <Link to={"https://tripsandtours.uk/testimonials"} target='_blank' className='text-[14px] sm:text-[18px] font-medium text-themeBlue border border-r-0 border-l-0 border-themeBlue px-2 py-1 sm:px-3 sm:py-2  shadow-[0_-2px_5px_rgba(0,0,0,0.1),0_2px_5px_rgba(0,0,0,0.1)] hover:border-themeRed hover:text-themeRed duration-300'>
                                    Reviews
                                </Link>
                                <Link to={"https://tripsandtours.uk/contact-us"} target='_blank' className='text-[14px] sm:text-[18px] font-medium text-themeBlue border border-r-0 border-l-0 border-themeBlue px-2 py-1 sm:px-3 sm:py-2  shadow-[0_-2px_5px_rgba(0,0,0,0.1),0_2px_5px_rgba(0,0,0,0.1)] hover:border-themeRed hover:text-themeRed duration-300'>
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <div className="text-center py-[60px] sm:py-[5%] h-screen animate-fadeIn">
                            <h3 className="text-3xl font-medium">No Trip Found</h3>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Home;
