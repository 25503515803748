import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setLogedInUser } from '../../store/features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const ForgotPassword = () => {
    const [form] = Form.useForm(); // Create a form instance

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { agencyLogo } = useSelector(state => state.agency);

    const goBack = () => {
        navigate(-1);
    }

    const dispatch = useDispatch();

    const forgotPassword = async (formData) => {
        setLoading(true);
        try {
            const BASE_URL = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(`${BASE_URL}/forgotpassword.php`, formData);
            if (response.data.message) {
                message.success(response.data.message);
                dispatch(setLogedInUser(response.data.user));
                form.resetFields(); // Clear form fields
                setLoading(false);
                navigate("/resetpassword");
                sessionStorage.setItem("email", formData.email);
            } else {
                message.error("Email not Registered");
            }
        } catch (error) {
            message.error("Email not Registered");
            setLoading(false);
        }
        setLoading(false);
    };

    return (
        <div className='w-full h-screen flex justify-center items-center'>
            <div className='max-w-md w-full px-5'>
                <div className='py-2 flex items-center justify-between'>
                    <button onClick={goBack} className='bg-themeRed text-white text-[18px] w-10 h-10 rounded-full hover:bg-themeBlue duration-200'>
                        <ArrowLeftOutlined />
                    </button>
                    <div onClick={() => navigate("/")} className="cursor-pointer flex justify-center items-center">
                        <img src={agencyLogo} alt="tripsandtours" width={200} />
                    </div>
                </div>
                <div className='bg-white shadow-xl rounded-lg px-8 py-10'>
                    <h2 className="text-2xl font-semibold mb-6">Reset Password</h2>
                    <Form
                        form={form} // Pass the form instance to the Form component
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={forgotPassword}
                    >
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please enter your email!' }]}
                        >
                            <Input
                                className='py-2 text-[16px]'
                                prefix={<UserOutlined className='text-gray-400' />}
                                placeholder="Enter your email" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="w-full bg-themeBlue text-[18px] py-1 font-medium h-full hover:bg-themeRed duration-300"
                                disabled={loading}
                                loading={loading} // Disable button while loading
                            >
                                Send Email
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
