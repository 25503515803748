import React from 'react'
import { useParams } from 'react-router-dom'
import BookingPersonCard from '../../components/bookingPersonCard/BookingPersonCard';
import Preloader from '../../components/preloader/Preloader';
import useFetch from '../../hooks/useFetch';
import TopBar from '../../components/topBar/TopBar';

const BookingDetail = () => {
  const { id } = useParams();
  const { data, loading } = useFetch(`/getbookingdetail.php?bid=${id}&t=${new Date().getTime()}`);
  let totalAmount = 0;

  if (data && data.length > 0) {
    data.forEach((person) => {
      totalAmount += parseInt(person.amount, 10) || 0;
    });
  }

  if (loading && !data) {
    return <Preloader />
  }

  return (
    <div>
      <TopBar/>
      {data && Object.keys(data).length > 0 ?
        <div className='container mx-auto my-5'>
          <h2 className='text-[20px] font-medium text-txtDark'>Booking Details</h2>
          <h2 className='text-[18px] text-txtDark mt-5'>Persons Information</h2>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1 gap-5 my-5">
            {data && data.length > 0 && data.map((person) => (
              <BookingPersonCard key={person.id} person={person} />
            ))}
          </div>
          <h2 className='text-[18px] text-txtDark my-5'>Billing Information</h2>
          <p className='text-[20px] font-semibold'>Total Amount : £{totalAmount}</p>
        </div> :
        <div className='text-center py-3'>
          {/* <h3 className='text-3xl font-medium'>No Booking Found</h3> */}
        </div>
      }
    </div>
  )
}

export default BookingDetail;
