import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import axios from 'axios';
import TopBar from '../../components/topBar/TopBar';
import { removeMyTrip } from '../../store/features/user/userSlice';
import checkedImg from "../../assets/images/checked.gif";
import { getEmailTemplate } from '../../utils/functions';

const Booking = () => {
    const [tripData, setTripData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [showPayPalSection, setShowPayPalSection] = useState(false); // State for PayPal section visibility
    const { id } = useParams();
    const { myTrips, logedInUser, isGuest } = useSelector(state => state.user);
    // const [bid, setBid] = useState("");
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const paypal = useRef();

    useEffect(() => {
        if (id && myTrips && myTrips.length > 0) {
            setTripData(myTrips.find((trip) => +trip.tripInfo.id === +id));
        }
    }, [id, myTrips]);

    // useEffect(() => {
    //     if (tripData.tripamount && showPayPalSection) { // Render PayPal section only when needed
    //         const createOrder = (data, actions) => {
    //             return actions.order.create({
    //                 intent: "CAPTURE",
    //                 purchase_units: [
    //                     {
    //                         description: "Trip Booking",
    //                         amount: {
    //                             currency_code: "GBP",
    //                             value: tripData.tripamount.toString()
    //                         }
    //                     }
    //                 ]
    //             });
    //         };

    //         const onApprove = async (data, actions) => {
    //             const order = await actions.order.capture();
    //             try {
    //                 if (order && order.id) {
    //                     const BASE_URL = process.env.REACT_APP_BASE_URL;
    //                     const response = await axios.post(`${BASE_URL}/updatebookingpayment.php`, { bid: bid, orderid: order.id });
    //                     if (response.status === 200) {
    //                         setIsModalOpen(true);
    //                     } else {
    //                         message.error("Something went wrong!");
    //                     }
    //                 }
    //             } catch (error) {
    //                 message.error("Something went wrong!");
    //             }
    //         };

    //         window.paypal.Buttons({
    //             createOrder: createOrder,
    //             onApprove: onApprove,
    //             onError: (err) => {
    //                 console.log(err);
    //             }
    //         }).render(paypal.current);
    //     }
    // }, [tripData, logedInUser, id, dispatch, form, navigate, showPayPalSection]);



    const bookTrip = async (formData) => {

        setLoading(true);
        try {
            let bookingData;
            if (isGuest && !logedInUser) {
                bookingData = {
                    customer_id: 0,
                    amount: tripData.tripamount,
                    email: formData.email,
                    fname: formData.fname,
                    lname: formData.lname,
                    phone: formData.phone,
                    point_id: tripData.pickupPoints.length > 0 ? formData.tripPickPoint : 0,
                    trip_id: +id,
                    orderid: null,
                    aid: "1",
                    booking_details: transformFormData(form.getFieldsValue())
                };
            } else {
                bookingData = {
                    customer_id: logedInUser.id,
                    amount: tripData.tripamount,
                    email: logedInUser.email,
                    fname: logedInUser.fname,
                    lname: logedInUser.lname,
                    phone: logedInUser.phone,
                    point_id: tripData.pickupPoints.length > 0 ? formData.tripPickPoint : 0,
                    trip_id: +id,
                    orderid: null,
                    aid: "1",
                    booking_details: transformFormData(form.getFieldsValue())
                };
            }

            const BASE_URL = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(`${BASE_URL}/addbooking.php`, bookingData);
            const currentDate = new Date();
            const formattedDateTime = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
            const emailData = {
                id: response.data.bid,
                title: tripData.tripInfo.title,
                bdate: formattedDateTime,
                status: "Pending",
                pname: formData.tripPickPoint ? tripData.pickupPoints.find(point => point.pid === formData.tripPickPoint).pname : '---',
                fname: bookingData.fname,
                lname: bookingData.lname,
                phone: bookingData.phone,
                email: bookingData.email,
                amount: bookingData.amount,
            }

            await axios.post(`${process.env.REACT_APP_BASE_URL}/paymentrequestemail.php`, {
                email: bookingData.email,
                email_template: getEmailTemplate(emailData),
            })

            setLoading(false);
            setIsModalOpen(true); // Show PayPal section upon form submission
        } catch (error) {
            console.error('Error while booking:', error);
            message.error("Something went wrong!");
        } finally {
            setLoading(false);
        }
    };

    const transformFormData = (formData) => {
        const bookingDetails = [];
        tripData.selectedPersons.forEach((person, index) => {
            [...Array(parseInt(person.quantity))].forEach((_, i) => {
                const firstName = formData[`firstName_${index}_${i}`] || '';
                const lastName = formData[`lastName_${index}_${i}`] || '';
                const otherData = formData[`otherData_${index}_${i}`] || '';
                bookingDetails.push({
                    firstname: firstName,
                    lastname: lastName,
                    otherdetails: person.agegroup + " / " + otherData,
                    amount: person.price,
                    type: person.agegroup.split("(")[0],
                });
            });
        });
        return bookingDetails;
    };


    const cancelTrip = () => {
        dispatch(removeMyTrip(id));
        navigate("/");
    };

    const renderFormFields = (person, index) => {
        const personType = person.agegroup.replace(/\(\d+-\d+\)/, '');
        const quantity = parseInt(person.quantity);

        if (quantity > 0) {
            return (
                <div key={index}>
                    <h3 className='text-[20px] mb-4 pb-1 border-b-4 border-themeRed inline-block w-[90px]'>{personType}</h3>
                    {[...Array(quantity)].map((_, i) => (
                        <div key={i}>
                            <h4>{person.agegroup.replace(/\(\d+-\d+\)/, '')} {i + 1}</h4>
                            <div className="grid sm:grid-cols-3 grid-cols-1  gap-x-3">
                                <Form.Item
                                    name={`firstName_${index}_${i}`}
                                    rules={[{ required: true, message: 'Please enter person First Name' }]}
                                >
                                    <Input
                                        className='py-2 text-[16px]'
                                        placeholder="First Name" />
                                </Form.Item>
                                <Form.Item
                                    name={`lastName_${index}_${i}`}
                                    rules={[{ required: false, message: 'Please enter person Last Name!' }]}
                                >
                                    <Input
                                        className='py-2 text-[16px]'
                                        placeholder="Last Name (Optional)" />
                                </Form.Item>
                                <Form.Item
                                    name={`otherData_${index}_${i}`}
                                    rules={[{ required: false, message: 'Please enter Person info!' }]}
                                >
                                    <Input
                                        className='py-2 text-[16px]'
                                        placeholder="Other Detail (Optional)" />
                                </Form.Item>
                            </div>
                        </div>
                    ))}
                </div>
            );
        } else {
            return null; // Return null if there are no selections for this age group
        }
    };

    const handleOk = () => {
        // setShowPayPalSection(false); // Hide PayPal section
        dispatch(removeMyTrip(id));
        navigate("/");
    };

    let tripPoints;

    if (Object.keys(tripData).length > 0 && tripData.pickupPoints.length > 0) {
        tripPoints = tripData.pickupPoints.map((item) => (
            {
                key: item.pid,
                value: item.pid,
                label: item.pname,
            }
        ))
    }



    return (
        <div>
            <TopBar />
            {tripData.selectedPersons &&  (
                <div className="container mx-auto my-6">
                    <div className='bg-white shadow-lg rounded-lg px-8 py-10'>
                        <h2 className="text-2xl font-semibold mb-6">Book Trip <span className='text-sm font-medium text-themeBlue'>  {"(" + tripData.tripInfo.title + ")"} </span></h2>
                        <Form
                            form={form}
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={bookTrip}
                        >
                            {isGuest && !logedInUser &&
                                <div className="my-3">
                                    <h3 className='text-[17px] font-medium mb-4'>Customer Info</h3>
                                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                                        <Form.Item
                                            name={`fname`}
                                            rules={[{ required: true, message: 'Please enter your First Name' }]}
                                        >
                                            <Input
                                                className='py-2 text-[16px]'
                                                placeholder="First Name" />
                                        </Form.Item>
                                        <Form.Item
                                            name={`lname`}
                                            rules={[{ required: true, message: 'Please enter your Last Name' }]}
                                        >
                                            <Input
                                                className='py-2 text-[16px]'
                                                placeholder="Last Name" />
                                        </Form.Item>
                                        <Form.Item
                                            name={`email`}
                                            rules={[{ required: true, message: 'Please enter your Email' }]}
                                        >
                                            <Input
                                                type="email"
                                                className='py-2 text-[16px]'
                                                placeholder="Email" />
                                        </Form.Item>
                                        <Form.Item
                                            name={`phone`}
                                            rules={[{ required: true, message: 'Please enter your Phone No' }]}
                                        >
                                            <Input
                                                className='py-2 text-[16px]'
                                                placeholder="Phone No" />
                                        </Form.Item>
                                    </div>

                                </div>
                            }
                            {tripData && Object.keys(tripData).length > 0 && tripData.pickupPoints.length > 0 &&
                                <div className='my-3'>
                                    <h4 className='font-medium mb-3 text-[18px]'>Select Pickup Point</h4>
                                    <Form.Item
                                        className='mb-0 col-span-2'
                                        name="tripPickPoint"
                                        rules={[{ required: true, message: 'Please Select Pickup Point' }]}
                                    >
                                        <Select
                                            placeholder="Select Point"
                                            size='large'
                                            className='w-[300px] sm:w-[400px]'
                                            options={tripPoints}
                                        />
                                    </Form.Item>
                                </div>
                            }

                            {tripData.selectedPersons.map((person, index) => (
                                <div key={index}>
                                    {renderFormFields(person, index)}
                                </div>
                            ))}
                            <div className='pt-3 pb-6'>
                                <h4 className='text-[20px] font-medium'>Your Bill : £{tripData.tripamount}</h4>
                            </div>
                            <div className='flex gap-6'>
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    className="w-full bg-themeBlue text-[18px] py-1 font-medium h-full hover:bg-themeRed duration-300"
                                    onClick={cancelTrip}
                                >
                                    Cancel Trip
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="w-full bg-themeBlue text-[18px] py-1 font-medium h-full hover:bg-themeRed duration-300"
                                    loading={loading}
                                >
                                    Book Now
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            )}

            {/* PayPal Section */}
            {/* {showPayPalSection && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white shadow-lg rounded-lg px-8 py-10 w-[300px] sm:w-[400px]">
                        <div ref={paypal}></div>
                        <Button
                            type="primary"
                            htmlType="button"
                            className="w-full bg-themeBlue text-[18px] py-1 font-medium h-full hover:bg-themeRed duration-300"
                            onClick={cancelTrip}
                        >
                            Cancel Trip
                        </Button>
                    </div>
                </div>
            )} */}

            {/* Modal */}
            <Modal
                centered
                open={isModalOpen}
                width="340px"
                footer={[]}
                closeIcon={null}
                maskClosable={false}
            >
                <div className='flex justify-center items-center text-center'>
                    <div>
                        <div className="flex justify-center items-center">
                            <img className='w-[130px]' src={checkedImg} alt="" />
                        </div>
                        <h3 className='text-themeBlue text
-center font-bold text-[20px] mb-3'>Booking Confirmed!</h3>
                        <h4 className='text-gray-700 text-[14px] text-center mb-5'>Your booking has been successfully completed. Please check your email for payment details and further instructions.</h4>
                        <button onClick={handleOk} className='shadow-md mb-3 rounded-md bg-themeBlue text-[16px] py-1 px-2 sm:text-[18px] sm:py-1 w-full sm:px-8 font-medium hover:bg-themeRed duration-300 text-white'>
                            OK
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Booking;
