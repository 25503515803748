import React, { useState } from "react";
import { UserOutlined, SignatureOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLogedInUser, setMyTrips } from "../../store/features/user/userSlice";
import { Modal, message } from "antd";
import { setBookingCheckout } from "../../store/features/booking/bookingSlice";
import agencyLogo from "../../assets/images/logo.webp"

const TopBar = () => {

    const { logedInUser, myBookings } = useSelector(state => state.user);
    const [logoutModalOpen, setLogoutModalOpen] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        dispatch(setLogedInUser(null));
        dispatch(setMyTrips([]));
        dispatch(setBookingCheckout(null));
        sessionStorage.clear();
        message.success("Loged Out Successfully");
        navigate("/");
    }

    const showModal = () => {
        setLogoutModalOpen(true);
    };
    const handleOk = () => {
        logout();
        setLogoutModalOpen(false);
    };
    const handleCancel = () => {
        setLogoutModalOpen(false);
    };


    return (
        <div className="container flex justify-between items-center border-themeBlue border-b-2 mx-auto py-3 px-1">
            <Link to="/"><img src={agencyLogo} alt="logo" className="w-[100px] sm:w-[150px] flex-none" /></Link>
            {logedInUser ?
                <div className="flex items-center gap-x-2 sm:gap-x-4">
                    <p className="text-[18px] font-medium">{logedInUser.fname}</p>
                    <button onClick={showModal} className="flex gap-2 shadow-md rounded-md items-center bg-themeBlue text-[16px] py-1 px-2 sm:text-[18px] sm:py-2 sm:px-3 font-medium hover:bg-themeRed duration-300 text-white ">
                        <span>Logout</span>
                    </button>
                    {myBookings && myBookings.length > 0 &&
                        <button onClick={() => navigate("/mybooking")} className="flex gap-2 shadow-md rounded-md items-center bg-themeBlue text-[16px] py-1 px-2 sm:text-[18px] sm:py-2 sm:px-3 font-medium hover:bg-themeRed duration-300 text-white ">
                            <span>Bookings</span>
                        </button>}
                </div> :
                <div className="flex gap-x-2 sm:gap-x-4">
                    <Link to="/signin" className="flex shadow-md rounded-md gap-2 items-center bg-themeBlue text-[16px] py-1 px-2 sm:text-[18px] sm:py-2 sm:px-3 font-medium hover:bg-themeRed duration-300 text-white ">
                        <UserOutlined />
                        <span>Sign In</span>
                    </Link>
                    <Link to="/register" className="flex gap-2 shadow-md rounded-md items-center bg-themeBlue text-[16px] py-1 px-2 sm:text-[18px] sm:py-2 sm:px-3 font-medium hover:bg-themeRed duration-300 text-white ">
                        <SignatureOutlined />
                        <span>Register</span>
                    </Link>
                </div>}
            <Modal
                okText="Yes"
                okButtonProps={{ className: "bg-themeRed text-white hover:bg-themeBlue font-medium" }}
                cancelButtonProps={{ className: "bg-themeBlue text-white border-none hover:bg-themeRed font-medium" }}
                open={logoutModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div className='text-center px-4 py-2 flex justify-center items-center'>
                    <div className="space-y-4">
                        <LogoutOutlined className='text-6xl text-themeRed' />
                        <p className='text-2xl font-medium'>Do you want to logout?</p>
                    </div>
                </div>
            </Modal>
        </div>

    );
};

export default TopBar;
