import React, { useEffect, useState } from 'react'
import Paypal from '../paypal/Paypal'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TopBar from '../topBar/TopBar';

const Checkout = () => {

    const [totalAmount, setTotalAmount] = useState("");
    const { id } = useParams();
    const { myTrips } = useSelector(state => state.user);

    useEffect(() => {
        if (myTrips && myTrips.length > 0) {
            const tripIndex = myTrips.findIndex((trip) => +trip.tripInfo.id === +id);
            const amount = myTrips[tripIndex].tripamount;
            setTotalAmount(amount);
        }
    }, [id, myTrips]);

    return (
        <>
            <TopBar />
            <div className='w-full flex items-center justify-center'>
                <div className='pt-[9%]'>
                    <h3 className='text-[20px] py-3'>Total Amount : £{totalAmount}</h3>
                    <div>
                        <Paypal />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Checkout