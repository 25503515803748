import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setLogedInUser } from '../../store/features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const Signin = () => {

    const [form] = Form.useForm(); // Create a form instance
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { agencyLogo } = useSelector(state => state.agency);
    const { myTrips } = useSelector(state => state.user);

    const goBack = () => {
        navigate(-1);
    }

    const dispatch = useDispatch();

    const loginUser = async (formData) => {
        setLoading(true);
        try {
            const BASE_URL = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(`${BASE_URL}/logincustomer.php`, { ...formData, aid: "1" });
            if (response.data.success) {
                if (response.data.user.isactive === 1) {
                    message.success('Logged In Successfully.');
                    dispatch(setLogedInUser(response.data.user));
                    form.resetFields(); // Clear form fields
                    setLoading(false);
                    if (myTrips && myTrips.length > 0) {
                        navigate(`/booking/${myTrips[0].tripId}`);
                    } else {
                        navigate("/");
                    }
                } else {
                    message.error("Account Not Activated verify email.");
                }
            } else {
                message.error("Invalid Credentials");
            }
        } catch (error) {
            message.error("Invalid Credentials");
            setLoading(false);
        }
        setLoading(false);
    };

    return (
        <div className='w-full h-screen flex justify-center items-center'>
            <div className='max-w-md w-full px-5'>
                <div className='py-2 flex items-center justify-between'>
                    <button onClick={goBack} className='bg-themeRed text-white text-[18px] w-10 h-10 rounded-full hover:bg-themeBlue duration-200'>
                        <ArrowLeftOutlined />
                    </button>
                    <div onClick={() => navigate("/")} className="flex cursor-pointer justify-center items-center">
                        <img src={agencyLogo} alt="tripsandtours" width={200} />
                    </div>
                </div>
                <div className='bg-white shadow-xl rounded-lg px-8 py-10'>
                    <h2 className="text-2xl font-semibold mb-6">Login</h2>
                    <Form
                        form={form} // Pass the form instance to the Form component
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={loginUser}
                    >
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please enter your email!' }]}
                        >
                            <Input
                                className='py-2 text-[16px]'
                                prefix={<UserOutlined className='text-gray-400' />}
                                placeholder="Enter your email" />
                        </Form.Item>

                        <Form.Item
                            className='mb-3'
                            name="password"
                            rules={[{ required: true, message: 'Please enter your password!' }]}
                        >
                            <Input.Password
                                className='py-2 text-[16px]'
                                prefix={<LockOutlined className='text-gray-400' />}
                                placeholder="Enter your password" />
                        </Form.Item>

                        <div className="flex justify-end mb-5">
                            <Link to="/forgotpassword" className="text-themeRed hover:underline">Forgot Password</Link>
                        </div>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="w-full bg-themeBlue text-[18px] py-1 font-medium h-full hover:bg-themeRed duration-300"
                                disabled={loading}
                                loading={loading} // Disable button while loading
                            >
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <p className="text-gray-500 text-sm mt-4 text-center">Don't have an account? <Link to="/register" className="text-themeRed hover:underline">Register now</Link></p>
            </div>
        </div >
    );
};

export default Signin;
