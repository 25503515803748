import React from 'react'
import { useSelector } from 'react-redux'
import TopBar from '../../components/topBar/TopBar';
import MyBookingCard from '../../components/myBookingCard/MyBookingCard';

const MyBooking = () => {

    const { myBookings } = useSelector(state => state.user);

    return (
        <div >
            <TopBar />
            <div className="container mx-auto my-5">
                <h2 className='text-[20px] font-medium text-txtDark'>My Bookings</h2>
                {myBookings && myBookings.length > 0 ?
                    <div className="grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1 gap-5 my-5">
                        {myBookings && myBookings.length > 0 && myBookings.map((booking) => (
                            <MyBookingCard key={booking.id} booking={booking} />
                        ))}
                    </div> :
                    <div className='my-5 text-center p-3'>
                        <h3 className='text-[24px]'>No Bokkings Available</h3>
                    </div>
                }
            </div>
        </div>
    )
}

export default MyBooking