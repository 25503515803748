import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import TripCard from '../tripCard/TripCard';
import Preloader from '../preloader/Preloader';
import TopBar from '../topBar/TopBar';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const Share = () => {

    const { id } = useParams();
    const [tripInfo, setTripInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tripImage, setTripImage] = useState("");
    const { allTrips, tripImages } = useSelector(state => state.trip);

    useEffect(() => {

        const info = allTrips.find((trip) => +trip.id === +id);
        let image = tripImages.filter((img) => img.tid === info.id);
        if (image) {
            setTripImage(image);
        }
        if (info) {
            setTripInfo(info);
            setLoading(false);
        }

    }, [id, allTrips, tripImages]);

    if (loading) {
        return <Preloader />;
    }

    return (
        <div>
            <TopBar />
            {tripInfo ? (
                <div className="container mx-auto py-[80px] flex items-center">
                    <Helmet>
                        <title>{tripInfo.title}</title>
                        <meta property="og:url" content={`${process.env.REACT_APP_SHARE_URL}${tripInfo.id}`} />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content={tripInfo.title} />
                        <meta property="og:image" content={tripImage.source} />
                    </Helmet>
                    <TripCard tripInfo={tripInfo} />
                </div>
            ) : (
                <div className="text-center py-[60px] sm:py-[5%] h-screen">
                    <h3 className="text-3xl font-medium">No Trip Found</h3>
                </div>
            )}
            <div className='pb-5'>
                <div className="container mx-auto">
                    <div className="flex justify-center items-center gap-2 sm:gap-6">
                        <Link to={"https://tripsandtours.uk/"} target='_blank' className='text-[14px] sm:text-[18px] font-medium text-themeBlue border border-r-0 border-l-0 border-themeBlue px-2 py-1 sm:px-3 sm:py-2  shadow-[0_-2px_5px_rgba(0,0,0,0.1),0_2px_5px_rgba(0,0,0,0.1)] hover:border-themeRed hover:text-themeRed duration-300'>
                            Visit Website
                        </Link>
                        <Link to={"/"} className='text-[14px] sm:text-[18px] font-medium text-themeBlue border border-r-0 border-l-0 border-themeBlue px-2 py-1 sm:px-3 sm:py-2  shadow-[0_-2px_5px_rgba(0,0,0,0.1),0_2px_5px_rgba(0,0,0,0.1)] hover:border-themeRed hover:text-themeRed duration-300'>
                            Trips
                        </Link>
                        <Link to={"https://tripsandtours.uk/testimonials"} target='_blank' className='text-[14px] sm:text-[18px] font-medium text-themeBlue border border-r-0 border-l-0 border-themeBlue px-2 py-1 sm:px-3 sm:py-2  shadow-[0_-2px_5px_rgba(0,0,0,0.1),0_2px_5px_rgba(0,0,0,0.1)] hover:border-themeRed hover:text-themeRed duration-300'>
                            Reviews
                        </Link>
                        <Link to={"https://tripsandtours.uk/contact-us"} target='_blank' className='text-[14px] sm:text-[18px] font-medium text-themeBlue border border-r-0 border-l-0 border-themeBlue px-2 py-1 sm:px-3 sm:py-2  shadow-[0_-2px_5px_rgba(0,0,0,0.1),0_2px_5px_rgba(0,0,0,0.1)] hover:border-themeRed hover:text-themeRed duration-300'>
                            Contact Us
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Share;