import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    agency: null,
    agencyLogo: "",
}

const agencySlice = createSlice({
    name: "agency",
    initialState,
    reducers: {
        setAgency: (state, action) => {
            state.agency = action.payload;

        },
        setAgencyLogo: (state, action) => {
            state.agencyLogo = action.payload;
        }
    }
});

export const { setAgency, setAgencyLogo } = agencySlice.actions;
export default agencySlice.reducer;