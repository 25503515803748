import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setLogedInUser } from '../../store/features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const VerifyEmail = () => {

    const [form] = Form.useForm(); // Create a form instance
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { myTrips } = useSelector(state => state.user);

    const goBack = () => {
        navigate(-1);
    }

    const dispatch = useDispatch();

    const loginUser = async (formData) => {
        setLoading(true);
        try {
            const BASE_URL = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(`${BASE_URL}/activateacc.php`, { token: formData.token });
            if (response.data.data) {
                dispatch(setLogedInUser(response.data.data));
                if (myTrips && myTrips.length > 0) {
                    navigate(`/booking/${myTrips[0].tripId}`);
                } else {
                    navigate("/");
                }
            } else {
                message.error("Invalid OTP");
            }

        } catch (error) {
            message.error("Invalid OTP");
        }
        setLoading(false);
    };

    return (
        <div className='w-full h-screen flex justify-center items-center'>
            <div className='max-w-md w-full px-5'>
                <div className='py-2'>
                    <button onClick={goBack} className='bg-themeRed text-white text-[18px] w-10 h-10 rounded-full hover:bg-themeBlue duration-200'>
                        <ArrowLeftOutlined />
                    </button>
                </div>
                <div className='bg-white shadow-xl rounded-lg px-8 py-10'>
                    <h2 className="text-[18px] mb-2">We have Sent OTP to your email</h2>
                    <h2 className="text-[18px] mb-6">Enter OTP to verify Your Email</h2>
                    <Form
                        form={form} // Pass the form instance to the Form component
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={loginUser}
                    >
                        <Form.Item
                            name="token"
                            rules={[{ required: true, message: 'Please enter your OTP' }]}
                        >
                            <Input
                                type='number'
                                className='py-2 text-[16px]'
                                prefix={<UserOutlined className='text-gray-400' />}
                                placeholder="Enter OTP ######" />
                        </Form.Item>


                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="w-full bg-themeBlue text-[18px] py-1 font-medium h-full hover:bg-themeRed duration-300"
                                disabled={loading}
                                loading={loading} // Disable button while loading
                            >
                                Verify Email
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div >
    );
};

export default VerifyEmail;
