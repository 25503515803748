import { useEffect, useState, useRef } from 'react';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { setAllTrips, setCategories, setPrices, setTripImages } from './store/features/trip/tripSlice';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './screens/home/Home';
import Signin from './components/signin/Signin';
import Register from './components/register/Register';
import Booking from './screens/booking/Booking';
import Preloader from './components/preloader/Preloader';
import Checkout from './components/checkout/Checkout';
import { setMyBookings } from './store/features/user/userSlice';
import MyBooking from './screens/myBooking/MyBooking';
import BookingDetail from './screens/bookingDetail/BookingDetail';
import ForgotPassword from './components/forgotPassword/ForgotPassword';
import ResetPassword from './components/resetPassword/ResetPassword';
import VerifyEmail from './components/verifyEmail/VerifyEmail';
import { setAgency, setAgencyLogo } from './store/features/agency/agencySlice';
import axios from 'axios';
import Share from './components/share/Share';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const prevLocationRef = useRef();
  const { logedInUser } = useSelector(state => state.user);

  // Consolidating all API requests in a single useEffect
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const timestamp = new Date().getTime();

      try {
        // Fetch agency data
        dispatch(setAgency({ aid: "1" }));
        const agencyPromise = axios.post(`${BASE_URL}/getagencybyid.php`, { aid: "1" });
        const allTripsPromise = axios.get(`${BASE_URL}/getalltrips.php/?aid=1&t=${timestamp}`);
        const tripImagesPromise = axios.get(`${BASE_URL}/tripimages.php?t=${timestamp}`);
        const pricesPromise = axios.get(`${BASE_URL}/getprices.php?t=${timestamp}`);
        const catPromise = axios.get(`${BASE_URL}/getallcat.php?t=${timestamp}`);
        const bookingsPromise = logedInUser
          ? axios.get(`${BASE_URL}/getallbooking.php/?aid=1&t=${timestamp}`)
          : Promise.resolve(null); // Return a resolved promise if not logged in

        const [agencyResp, allTripsResp, tripImagesResp, pricesResp, catResp, bookingsResp] = await Promise.all([
          agencyPromise,
          allTripsPromise,
          tripImagesPromise,
          pricesPromise,
          catPromise,
          bookingsPromise,
        ]);

        // Process agency data
        if (agencyResp.status === 200) {
          dispatch(setAgencyLogo(`${BASE_URL}/logo/${agencyResp.data.data[0].logo}`));
        }

        // Process trip data
        if (allTripsResp.data && tripImagesResp.data && pricesResp.data) {
          const currentDate = new Date();
          const filteredTrips = allTripsResp.data
            .filter(trip => new Date(trip.startdate) >= currentDate)
            .sort((a, b) => new Date(a.startdate) - new Date(b.startdate));

          dispatch(setAllTrips(filteredTrips.filter((item) => +item.isdeleted === 0 && +item.isactive === 1)));
          dispatch(setTripImages(tripImagesResp.data));
          dispatch(setPrices(pricesResp.data));
          dispatch(setCategories(catResp.data));
        }

        // Process bookings data if available
        if (bookingsResp?.data && logedInUser) {
          dispatch(setMyBookings(bookingsResp.data.filter((booking) => +booking.customerid === logedInUser.id)));
        }
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false); // Ensure loading stops once data is processed
      }
    };

    fetchData();
  }, [dispatch, logedInUser]);

  // Scroll to top when the location changes
  useEffect(() => {
    if (prevLocationRef.current !== location.pathname) {
      window.scrollTo(0, 0);
      prevLocationRef.current = location.pathname;
    }
  }, [location.pathname]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/signin" element={<Signin />} />
      <Route path="/register" element={<Register />} />
      <Route path="/verifyemail" element={<VerifyEmail />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/booking/:id" element={<Booking />} />
      <Route path="/checkout/:id" element={<Checkout />} />
      <Route path="/mybooking" element={<MyBooking />} />
      <Route path="/share/:id" element={<Share />} />
      <Route path="/bookingdetail/:id" element={<BookingDetail />} />
    </Routes>
  );
}

export default App;
