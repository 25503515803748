import React, { memo } from 'react';
import { formatDate } from '../../utils/functions';
import { useNavigate } from 'react-router-dom';

const MyBookingCard = ({ booking }) => {

    const navigate = useNavigate();

    return (
        <div>
            <div className='flex justify-between items-center mb-3'>
                <p className='text-[15px] font-medium mb-1'>Status : {booking.status[0].toUpperCase() + booking.status.slice(1)}</p>
            </div>
            <div
                onClick={() => navigate(`/bookingdetail/${booking.id}`)}
                className='border border-themeBlue rounded-lg cursor-pointer hover:bg-[#f5f5f5] duration-300'>
                <div className='p-3'>
                    <p className='text-[15px] font-medium mb-1'>Booking ID : {booking.id}</p>
                    <p className='text-[15px] font-medium mb-1'>Order ID : {booking.orderid}</p>
                    <p className='text-[15px] font-medium mb-1'>Trip ID : {booking.tid}</p>
                    <p className='text-[15px] font-medium mb-1'>Booking Date : {formatDate(booking.bdate.split(" ")[0])}</p>
                    <p className='text-[15px] font-medium mb-1'>Booking Time : {booking.bdate.split(" ")[1]}</p>
                </div>
                <div className='py-2 px-3 rounded-b-md bg-themeBlue flex justify-between items-center text-white'>
                    <p className='text-[16px] font-medium'>Amount </p>
                    <p className='text-[16px] font-medium'>£{booking.amount}</p>
                </div>
            </div>
        </div>

    )
}

export default memo(MyBookingCard);