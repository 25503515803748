import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    logedInUser: null,
    myBookings: [],
    myTrips: [],
    isGuest: false,
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setLogedInUser: (state, action) => {
            state.logedInUser = action.payload;
        },
        setMyBookings: (state, action) => {
            state.myBookings = action.payload;
        },
        addMyTrip: (state, action) => {
            state.myTrips[0] = action.payload;
        },
        removeMyTrip: (state, action) => {
            state.myTrips = state.myTrips.filter((trip) => trip.tripInfo.id !== action.payload);
        },
        setMyTrips: (state, action) => {
            state.myTrips = action.payload;
        },
        setIsGuest: (state, action) => {
            state.isGuest = action.payload;
        }
    }
});

export const { setLogedInUser, setMyBookings, addMyTrip, setMyTrips, removeMyTrip, setIsGuest } = userSlice.actions;
export default userSlice.reducer;
