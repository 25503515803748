import { combineReducers, configureStore } from '@reduxjs/toolkit';
import tripReducer from "./features/trip/tripSlice";
import userReducer from "./features/user/userSlice";
import bookingReducer from "./features/booking/bookingSlice";
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session'; // Use sessionStorage here
import agencyReducer from "./features/agency/agencySlice";

const persistConfig = {
    key: "root",
    version: 1,
    storage: sessionStorage, // Use sessionStorage instead of localStorage
    whitelist: ['user', "agency"], // Specify the states you want to persist
};

const rootReducer = combineReducers({
    trip: tripReducer,
    user: userReducer,
    booking: bookingReducer,
    agency: agencyReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;
