import { Modal, message } from 'antd';
import axios from 'axios';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { removeMyTrip } from '../../store/features/user/userSlice';
import checkedImg from "../../assets/images/checked.gif";
import { setBookingCheckout } from '../../store/features/booking/bookingSlice';

const Paypal = () => {
    const paypal = useRef();
    // const { bookingCheckout } = useSelector(state => state.booking);
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { agency } = useSelector(state => state.agency);
    const billRef = useRef(sessionStorage.getItem("bill").toString());
    const bookingRef = useRef(sessionStorage.getItem("checkoutData"));
    const bookingCheckout = JSON.parse(bookingRef.current);

    useEffect(() => {
        const createOrder = (data, actions) => {
            return actions.order.create({
                intent: "CAPTURE",
                purchase_units: [
                    {
                        description: "Cool Looking Table",
                        amount: {
                            currency_code: "GBP",
                            value: billRef.current
                        }
                    }
                ]
            });
        };

        const onApprove = async (data, actions) => {
            const order = await actions.order.capture();
            const checkout = { ...bookingCheckout, orderid: order.id, aid: "1" };
            const BASE_URL = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(`${BASE_URL}/addbooking.php`, checkout);
            if (response.status === 200) {
                message.success("Booking Done Successfully!");
                dispatch(removeMyTrip(id));
                dispatch(setBookingCheckout(null));
                sessionStorage.setItem("bill", "");
                sessionStorage.setItem("checkoutData", "");
                navigate("/");
            } else {
                message.error("Something went wrong!");
            }
        };

        window.paypal.Buttons({
            createOrder: createOrder,
            onApprove: onApprove,
            onError: (err) => {
                console.log(err);
            }
        }).render(paypal.current);

    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleOk = () => {
        handleCancel();
        navigate("/");
    };

    return (
        <div className='w-[340px] overflow-y-auto h-[400px]'>
            <div ref={paypal}></div>
            <Modal
                centered
                open={isModalOpen}
                width="250px"
                footer={[]}
                closeIcon={null}

            >
                <div className='flex justify-center items-center'>
                    <div>
                        <div className="flex justify-center items-center">
                            <img className='w-[130px]' src={checkedImg} alt="" />
                        </div>
                        <h3 className='text-themeBlue text-center font-medium text-[20px]'>Booking Done!</h3>
                        <h4 className='text-gray-700 text-[14px] text-center'>Check Your Email</h4>
                        <button onClick={handleOk} className='shadow-md mt-4 rounded-md bg-themeBlue text-[16px] py-1 px-2 sm:text-[18px] sm:py-1 w-full sm:px-8 font-medium hover:bg-themeRed duration-300 text-white'>
                            OK
                        </button>
                    </div>
                </div>
            </Modal>

        </div>
    );
};

export default memo(Paypal);
