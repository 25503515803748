import React, { memo } from 'react'

const BookingPersonCard = ({ person }) => {
    return (
        <div className='border border-themeBlue rounded-lg cursor-pointer hover:bg-[#f5f5f5] duration-300'>
            <div className='p-3'>
                <p className='text-[15px] font-medium mb-1'>Person ID : {person.id}</p>
                <p className='text-[15px] font-medium mb-1'>First Name : {person.firstname}</p>
                <p className='text-[15px] font-medium mb-1'>Last Name : {person.lastname}</p>
                <p className='text-[15px] font-medium mb-1'>Person Info : {person.otherdetails}</p>
            </div>
            <div className='py-2 px-3 rounded-b-md bg-themeRed flex justify-between items-center text-white'>
                <p className='text-[16px] font-medium'>Amount </p>
                <p className='text-[16px] font-medium'>£{person.amount}</p>
            </div>
        </div>
    )
}

export default memo(BookingPersonCard);